import React, { useState } from 'react';
import './Popup.css'; // Make sure to create a corresponding CSS file for styles
import NewsletterSignup from './NewsletterSignup';

const Popup = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="popup-container" a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
      <div className="popup">
        <div className="popup-close" onClick={handleClose}>×</div>
        <img src="favicon.ico" alt="Favicon" className="popup-favicon"  />
        <div className="popup-text">
          <div className="popup-title">Bible Way</div>
          <div className="popup-description">Expirence the Bible in a whole new way</div>
        </div>
        {/* <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`} alt="Download on the App Store" className="popup-appstore" />
        </a> */}
        <a href="https://apple.co/3V8Zy51" target="_blank" rel="noopener noreferrer">
        <img src={`${process.env.PUBLIC_URL}/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`} alt="Download on the App Store" style={{cursor: 'pointer' }} className="popup-appstore" />
        </a>
      </div>
    </div>
  );
};

export default Popup;
